<template>
  <v-row>
    <!-- <v-col cols="3">
      <v-card-subtitle class="discharge-title">
        {{ $t("examination.dischargeSummary.diagnosis") }} :
      </v-card-subtitle>
    </v-col> -->
    <v-col cols="12">
      <v-card-subtitle class="discharge-title mb-1">
        {{ $t("examination.dischargeSummary.diagnosis") }} :
        <span style="margin-left: 11%" v-if="!diagnosis.length">{{
          $t("examination.dischargeSummary.noDiagnosis")
        }}
      </span>
      </v-card-subtitle>
      <div v-if="checkDuplicateTwoRecord(primarys) !== 'none'" class="d-block mb-9">
        <v-chip class="ml-2 float-left" label small text-color="white" color="primary">
          Primary
        </v-chip>
      </div>
        <div class="d-block" v-if="checkDuplicateTwoRecord(primarys) === 'Duplicate'">
          <div class="d-flex flex-column mb-3">
            <div class="d-block mb-3">
              <!-- <v-chip class="ml-2 float-left" label small text-color="white" color="primary">
                Primary
              </v-chip> -->
            </div>
            <v-row>
              <v-col cols="2" class="text-center">
                <b class="ml-3"> Cả 2 mắt: </b> 
              </v-col>
              <v-col cols="10" class="go-left">
                <div class="d-block mb-3 go-left">
                  <div class="d-flex flex-column ml-3">
                    <span class="text-body-4 lh-1">
                      <b>{{dataPrimarys.icdDxCode}}</b> - {{dataPrimarys.icdDxDescription}}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="checkDuplicateTwoRecord(primarys) === 'noDuplicate'" class="form-table mt-4">
          <v-row class="top">
            <v-col cols="6" class="text-center">
              <h3>Mắt phải</h3>
            </v-col>
            <v-col cols="6" class="text-center">
              <h3>Mắt trái</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="pos of EyeType" :key="pos">
              <div class="d-block">
                <div class="d-flex flex-column mb-3">
                  <div class="d-block mb-3">
                    <!-- <v-chip class="float-left" label small text-color="white" color="primary">
                      Primary
                    </v-chip> -->
                  </div>
                  <div class="d-flex flex-column" v-for="(item, idx) in primarys" :key="idx" >
                    <span class="pl-3 text-body-4 mb-2" v-if="item.diagnosisCodePosition == pos">
                      <b>{{item.icdDxCode}}</b> - {{item.icdDxDescription}}
                    </span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="dataAdditionnals.arrDup.length || dataAdditionnals.arrNoDup.length" class="d-block mb-11 mt-2">
          <v-chip class="ml-2 float-left" label small text-color="white" color="primary">
            Additional
          </v-chip>
        </div>
        <div v-if="dataAdditionnals.arrDup.length" class="d-block mt-3" >
          <div class="d-flex flex-column mb-3">
            <div class="d-block mb-3">
              <!-- <v-chip class="ml-2 float-left" label small text-color="white" color="primary">
                Additional
              </v-chip> -->
            </div>
            <v-row>
              <v-col cols="2" class="text-center">
                <b class="ml-3" > Cả 2 mắt: </b> 
              </v-col>
              <v-col cols="10" class="go-left">
                <div class="d-block mb-3 go-left">
                  <div v-for="(item, idx) in dataAdditionnals.arrDup" :key="idx" class="d-flex flex-column ml-3">
                    <span class="text-body-4 lh-1">
                      <b>{{item.icdDxCode}}</b> - {{item.icdDxDescription}}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="dataAdditionnals.arrNoDup.length" class="form-table mt-2">
          <v-row class="top">
            <v-col cols="6" class="text-center">
              <h3>Mắt phải</h3>
            </v-col>
            <v-col cols="6" class="text-center">
              <h3>Mắt trái</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="pos of EyeType" :key="pos">
              <div class="d-block">
                <div class="d-flex flex-column mb-3">
                  <div class="d-block mb-3">
                    <!-- <v-chip class="float-left" label small text-color="white" color="primary">
                      Additional
                    </v-chip> -->
                  </div>
                    <div class="d-flex flex-column float-left" v-for="(item, idx) in dataAdditionnals.arrNoDup" :key="idx" >
                      <span class="pl-3 text-body-4 mb-1 lh-1" v-if="item.diagnosisCodePosition == pos">
                        <b>{{item.icdDxCode}}</b> - {{item.icdDxDescription}}
                      </span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="dataSuspected.arrDup.length || dataSuspected.arrNoDup.length" class="d-block mb-11 mt-2">
          <v-chip class="ml-2 float-left" label small text-color="white" color="primary">
            Suspected
          </v-chip>
        </div>
        <div v-if="dataSuspected.arrDup.length" class="d-block m-3" >
          <div class="d-flex flex-column mb-3">
            <div class="d-block mb-3">
              <!-- <v-chip class="ml-2 float-left" label small text-color="white" color="primary">
                Suspected
              </v-chip> -->
            </div>
            <v-row>
              <v-col cols="2" class="text-center">
                <b class="ml-3" > Cả 2 mắt: </b> 
              </v-col>
              <v-col cols="10" class="go-left">
                <div class="d-block mb-1 go-left">
                  <div v-for="(item, idx) in dataSuspected.arrDup" :key="idx" class="d-flex flex-column ml-3">
                    <span class="text-body-4 lh-1">
                      <b>{{item.icdDxCode}}</b> - {{item.icdDxDescription}}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="dataSuspected.arrNoDup.length" class="form-table mt-4">
          <v-row class="top">
            <v-col cols="6" class="text-center">
              <h3>Mắt phải</h3>
            </v-col>
            <v-col cols="6" class="text-center">
              <h3>Mắt trái</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="pos of EyeType" :key="pos">
              <div class="d-block">
                <div class="d-flex flex-column mb-3">
                  <div class="d-block mb-2">
                    <!-- <v-chip class="float-left" label small text-color="white" color="primary">
                      Suspected
                    </v-chip> -->
                  </div>
                  <div class="d-flex flex-column float-left" v-for="(item, idx) in dataSuspected.arrNoDup" :key="idx" >
                    <span class="pl-3 text-body-4 mb-2 lh-1" v-if="item.diagnosisCodePosition == pos">
                      <b>{{item.icdDxCode}}</b> - {{item.icdDxDescription}}
                    </span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
    </v-col>
  </v-row>
</template>

<script>
import DiagnosisService from "@/services/diagnosis";
import { EyeType } from "@/plugins/constant";

export default {
  data() {
    return {
      diagnosis: [],
      primarys: [],
      additionals: [],
      suspected: [],
      EyeType,
      dataPrimarys: {},
      dataAdditionnals: {
        arrDup: [],
        arrNoDup: []
      },
      dataSuspected: {
        arrDup: [],
        arrNoDup: []
      },
    };
  },
  watch: {
    dataAdditionnals(val) {
      console.log(val);
    }
  },
  mounted() {},
  async created() {
    this.renderDiagnosis();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    getCodePositionName(code) {
      return code == 1 ? "Mắt phải" : code == 2 ? "Mắt trái" : "Hai mắt";
    },
    async renderDiagnosis() {
      const apiResult = await DiagnosisService.SearchListDiagnosis(
        this.$route.params.episodeRecordID,
        1,
        100
      );
      if (!apiResult || apiResult.error) {
        this.showError(
          "Cannot get Diagnosis at the moment! Please try again later."
        );
      }
      this.diagnosis = apiResult.items;
      this.primarys = apiResult.items.filter((i) => 
        i.diagnosisCode === "Primary"
      );
      this.additionals = apiResult.items.filter((i) => 
        i.diagnosisCode === "Additional"
      );
      this.suspected = apiResult.items.filter((i) => 
        i.diagnosisCode === "Suspected"
      );
      this.checkDuplicate(this.additionals, this.dataAdditionnals);
      this.checkDuplicate(this.suspected, this.dataSuspected);
    },
    checkDuplicateTwoRecord(array = []){
      if(array.length > 1) {
        if(array[0].icdDxID == array[1].icdDxID){
          this.dataPrimarys = array[0];
          return "Duplicate";
        }
        return "noDuplicate";
      }
      else if (array.length == 1) {
        return "noDuplicate";
      }
      else if (array.length == 0) {
        return "none";
      }
    },
    checkDuplicate(array = [], dataReturn = {}) {
      let counts = {};
      var arrDup = [];
      var arrNoDup = [];
      for(let i = 0; i < array.length; i++){
        if (counts[array[i].icdDxID]) {
          counts[array[i].icdDxID] += 1
        } else {
          counts[array[i].icdDxID] = 1
        }
        if(counts[array[i].icdDxID] == 2) {
          arrDup.push(array[i]);
        }
      }  
      array.forEach(item => {
        if(arrDup.find(dupItem => dupItem.icdDxID == item.icdDxID)) {
          return;
        }
        arrNoDup.push(item);
      });
      dataReturn.arrDup = arrDup;
      dataReturn.arrNoDup = arrNoDup;
      return dataReturn;
    }
  },
};
</script>

<style scoped lang="scss">
.form-table {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-right: 0px;
  > .row {
    margin: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    margin-bottom: -1px;
    .col {
      border-right: 1px solid rgba(0, 0, 0, 0.6);
    }
  }
}
.lh-1 {
  line-height: 1.5;
}
.v-chip {
  font-size: 16px;
}
.go-left{
  padding-left: 0px;
  margin-left: inherit;
}
</style>