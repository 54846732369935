<template>
  <v-row class="mt-3">
    <v-col cols="12">
      <v-card-subtitle class="discharge-title">
        Procedure / Accessment :
        <span v-if="!procedureAccessment.length">{{
          $t("examination.dischargeSummary.noAxPx")
        }}</span> 
      </v-card-subtitle>
      <div v-if="dataProcedureAccessment.arrDup.length" class="d-block mt-0" >
        <div class="d-flex flex-column mb-3">
          <div class="d-block mb-3">
            </div>
              <v-row>
                <v-col cols="2" class="text-center">
                  <b class="ml-3" > Cả 2 mắt: </b> 
                </v-col>
                <v-col cols="10" class="go-left">
                  <div class="d-block mb-3 go-left">
                    <div v-for="(item, idx) in dataProcedureAccessment.arrDup" :key="idx" class="d-flex flex-column ml-3">
                      <span class="text-body-4 lh-1">
                        <b>{{item.icdCode}}</b> - {{item.icdDescription}}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        <div v-if="dataProcedureAccessment.arrNoDup.length" class="form-table mt-2">
          <v-row class="top">
            <v-col cols="6" class="text-center">
              <h3>Mắt phải</h3>
            </v-col>
            <v-col cols="6" class="text-center">
              <h3>Mắt trái</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="pos of EyeType" :key="pos">
              <div class="d-block">
                <div class="d-flex flex-column mb-3">
                  <div class="d-block mb-3">
                    <!-- <v-chip class="float-left" label small text-color="white" color="primary">
                      Additional
                    </v-chip> -->
                  </div>
                    <div class="d-flex flex-column float-left" v-for="(item, idx) in dataProcedureAccessment.arrNoDup" :key="idx" >
                      <span class="pl-3 text-body-4 mb-1 lh-1" v-if="item.treatmentCodePosition == pos">
                        <b>{{item.icdCode}}</b> - {{item.icdDescription}}
                      </span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TreatmentService from "@/services/treatment";
import { EyeType } from "@/plugins/constant";

export default {
  data() {
    return {
      EyeType,
      procedureAccessment: [],
      dataProcedureAccessment: {
        arrDup: [],
        arrNoDup: []
      },
    };
  },
  mounted() {},
  async created() {
    this.renderTreatment();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    getCodePositionName(code) {
      return code == 1 ? "Mắt phải" : code == 2 ? "Mắt trái" : "Hai mắt";
    },
    async renderTreatment() {
      const apiResult = await TreatmentService.SearchListTreatment(
        this.$route.params.episodeRecordID,
        1,
        100
      );
      console.log(apiResult);
      if (!apiResult || apiResult.error) {
        this.showError(
          "Cannot get Procedure/Accessment at the moment! Please try again later."
        );
      }
      this.checkDuplicate(apiResult.items, this.dataProcedureAccessment);
      this.procedureAccessment = apiResult.items;
    },
    checkDuplicate(array = [], dataReturn = {}) {
      let counts = {};
      var arrDup = [];
      var arrNoDup = [];
      for(let i = 0; i < array.length; i++){
        if (counts[array[i].icdid]) {
          counts[array[i].icdid] += 1
        } else {
          counts[array[i].icdid] = 1
        }
        if(counts[array[i].icdid] == 2) {
          arrDup.push(array[i]);
        }
      }  
      array.forEach(item => {
        if(arrDup.find(dupItem => dupItem.icdid == item.icdid)) {
          return;
        }
        arrNoDup.push(item);
      });
      dataReturn.arrDup = arrDup;
      dataReturn.arrNoDup = arrNoDup;
      return dataReturn;
    }
  },
};
</script>
<style scoped lang="scss">
.form-table {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-right: 0px;
  > .row {
    margin: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    margin-bottom: -1px;
    .col {
      border-right: 1px solid rgba(0, 0, 0, 0.6);
    }
  }
}
.lh-1 {
  line-height: 1.6;
}
.go-left{
  padding-left: 0px;
  margin-left: inherit;
}
</style>