import HttpClient from '../plugins/httpClient'
//import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas);

const prefix = '/Referral';

export default {

    async getReferral(episodeRecordID) {
        var query = {
            episodeRecordID
        }
        return httpClient.get(`${prefix}/GetByID/{episodeRecordID}`, query);
    },
    getReferralDetail(episodeRecordID) {
        var query = {
            episodeRecordID
        }
        return httpClient.get(`${prefix}/GetByID/{episodeRecordID}`, query);
    },
    createReferral(episodeRecordID, data) {
        return httpClient.post(`${prefix}/Create/${episodeRecordID}`, {}, data);
    },
    updateReferral(referralID, data) {
        return httpClient.put(`${prefix}/Update/${referralID}`, {}, data);
    },
    deleteAxPxReferral(id) {
        var query = {
            id
        }
        return httpClient.put(`${prefix}/Delete/{id}`, query);
    },
}