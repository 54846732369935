import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas)

export default {
    searchDischargeMode: async (keySearch, page = 1, limit = 10) => {
        var query = {
            keySearch,
        }
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit,
        }
        return httpClient.get(`/Discharge/DischargeMode/Search`, query, headers);
    },

    searchPatientSatisfaction: async (keySearch, page = 1, limit = 10) => {
        var query = {
            keySearch,
        }
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit,
        }
        return httpClient.get(`/Discharge/PatientSatisfaction/Search`, query, headers);
    },

    getDischargeDetail(episodeRecordID) {
        var query = {
            episodeRecordID
        }
        return httpClient.get(`/Discharge/Get/{episodeRecordID}`, query);
    },
    createDischarge: async (episodeRecordID, data) => {
        return httpClient.post(`Discharge/Create/${episodeRecordID}`, {}, data);
    },
}