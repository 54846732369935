import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import AppConstant from '../plugins/constant'
var httpClient = new HttpClient(ApiGateway.Emr);

export default {
  async SearchListTreatment(keySearch = "", page = 1, limit = 10) {
    var query = {
      keySearch,
    }
    var header = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit,
    }
    return httpClient.get("/Treatment/Search", query, header);
  },
  async SearchListICD(keySearch = "", statusID = 1, page = 1, limit = 10) {
    var query = {
      keySearch,
      statusID,
    }
    var header = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit,
    }
    return httpClient.get("/ICD/Search", query, header);
  },
  async CreateTreatmentWithListICD(bodyData) {
    return httpClient.post("/Treatment/CreateWithListICCIDs", {}, bodyData);
  },
  async DeleteTreatmentByID(treatmentID) {
    var query = {
      treatmentID,
    }
    return httpClient.delete("/Treatment/Delete/{treatmentID}", query);
  },
}